<template>
  <TaskDetailItemEditCard confirmation-message="Taakinformatie werd succesvol gewijzigd" title="Bewerk taakinformatie"
    update-store-action="tasksModule/updateTaskInformation"
    :update-store-action-payload="{ projectId: projectId, clientId: clientId, taskId: taskId, payload: getTask }"
    :projectId="projectId" :clientId="clientId" :taskId="taskId" :promises.sync="promises">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <DatePicker v-model="taskInformation.startDate" label="Start datum" :default-now="false" />
        </v-col>
        <v-col cols="12" md="6">
          <DatePicker v-model="taskInformation.endDate" label="Eind datum"
            :min="taskInformation.startDate ? new Date(taskInformation.startDate) : null" :default-now="false" />
        </v-col>
        <v-col  cols="12" md="6">
          <v-text-field required persistent-placeholder label="Aantal nodige personeel" type="number"
            v-model="taskInformation.usersRequired" min=1
            :rules="[...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <DurationInput v-model="taskInformation.duration" label="Duurtijd" required/>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="4" md="8" sm="8" xs="12">
          <v-textarea outlined label="Beschrijving" clearable clear-icon="mdi-close-circle"
            :rules="[v => (v == null || v.length <= 600) || 'Beschrijving kan maximaal uit 600 karakters bestaan']"
            v-model="taskInformation.description" />
        </v-col>
      </v-row>

    </template>
  </TaskDetailItemEditCard>
</template>

<script>
import TaskDetailItemEditCard from "@/views/planning/forms/TaskDetailItemEditCard.vue"
import routeNames from "@/router/RouteNames";
import { getRequiredRules, getBiggerThanZeroRules } from "@/shared/utils/inputRulesUtils.js"
import DatePicker from "@/components/shared/fields/DatePicker.vue"
import DurationInput from "../../../components/shared/fields/DurationInput.vue";
import { DetailedTimeStamp } from "@/shared/types/DetailedTimeStamp"
import { formatDateAsDay } from "@/shared/utils/dateUtils"

export default {
  name: "TaskDetailEditTaskInformation",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      routeNames,
      taskInformation: {
        startDate: null,
        endDate: null,
        duration: null,
        usersRequired: 1,
        description: null,
      },
      promises: [
        this.getTaskInformation()
      ],
    }
  },
  components: {
    TaskDetailItemEditCard,
    DatePicker,
    DurationInput,
  },
  methods: {
    getRequiredRules,
    getBiggerThanZeroRules,
    getTaskInformation() {
      return this.$store.dispatch("tasksModule/fetchTaskGeneral", { clientId: this.clientId, projectId: this.projectId, taskId: this.taskId })
        .then(result => {
          const startDateValue = result?.startDate
            ? formatDateAsDay(DetailedTimeStamp.fromJson(result.startDate).getLocalTimeAsDate())
            : null; // default value
          const endDateValue = result?.endDate
            ? formatDateAsDay(DetailedTimeStamp.fromJson(result.endDate).getLocalTimeAsDate())
            : null; // default value
          this.taskInformation = {
            ...result,
            startDate: startDateValue,
            endDate: endDateValue,
          }
        })
    }
  },
  computed: {
    // Returns data back to TaskDetailItemEditCard where it will be dispatched to the appropriate action
    getTask() {
      const startDateValue = this.taskInformation?.startDate
        ? DetailedTimeStamp.fromUTCTime(new Date(this.taskInformation?.startDate))
        : null
      const endDateValue = this.taskInformation?.endDate
        ? DetailedTimeStamp.fromUTCTime(new Date(this.taskInformation?.endDate))
        : null
      
      return {
        ...this.taskInformation,
        startDate: startDateValue,
        endDate: endDateValue,
      }
    },
  }
}
</script>